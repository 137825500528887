import { NavLink, Link, useNavigate } from "react-router-dom";
import logo from "../assets/ConnectedNewsrooms.svg";
import ladyperson from "../assets/saveavatar.jpg";
import notificate from "../assets/notification-bing.svg";
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";
import { useQuery } from "@tanstack/react-query";
import { getNotification } from "../api";
import { useState } from "react";

const MainNavbar = () => {
    const { setUser, setToken } = useProtectedRoutesContext();
    const navigate = useNavigate();

    let storedObject = localStorage.getItem('user');
    let firstname = '';
    let lastname = '';
    let jobtitle = '';
    let avatar = '';

    if (storedObject) {
        let parsedObject = JSON.parse(storedObject);
        firstname = parsedObject?.first_name;
        lastname = parsedObject?.last_name;
        jobtitle = parsedObject?.job_title;
        avatar = parsedObject?.avatar;
    } else {
        console.log('Object not found in local storage');
    }

    const { data } = useQuery({
        queryKey: ["getNotification"],
        queryFn: () => getNotification(),
    });

    const [pageNumber] = useState(0);
    const resultsPerPage = 5;
    const pagesVisited = pageNumber * resultsPerPage;

    return (
        <div className="">
            <nav className="navbar navbar-bcg navbar-expand-lg">
                <div className="container">
                    <Link className="navbar-brand"><img src={logo} alt="Logo" className="profile-logo" /></Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item">
                                <NavLink exact to="/queries" className="nav-link" activeClassName="active">Queries</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink exact to="/myleaks" className="nav-link" activeClassName="active">Leaks</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink exact to="/profilesearch" className="nav-link" activeClassName="active">Profile Search</NavLink>
                            </li>
                        </ul>

                        <form className="form-inline my-2 my-lg-0">
                            <div className="nav-item dropdown">
                                <a href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img src={notificate} alt="Notifications" />
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    {data?.data?.data && data.data.data.length > 0 ? (
                                        <div>
                                            <p className="section-title">Latest notifications</p>
                                            {data?.data?.data?.slice(pagesVisited, pagesVisited + resultsPerPage).map((notification, index) => (
                                                <div key={index} className="profile-person-flex-bg">
                                                    <div className="profile-person-flex">
                                                        <Link className="dropdown-item notice" onClick={() => navigate(`/queries/${notification?.query_uuid}`, { replace: true })}>
                                                            {notification.message}
                                                        </Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p>You don't have any notifications</p>
                                    )}
                                </div>
                            </div>

                            <div className="nav-item dropdown">
                                <a href="#" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {avatar ? (<img src={avatar} alt="Avatar" className="profile-person-img" />) : (<img className="koconnected-img" src={ladyperson} alt="Default Avatar" />)}
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <div className="dropdown-container-profile">
                                        <div className="person-container">
                                            {avatar ? (<img src={avatar} alt="Profile" className="person-profile-display" />) : (<img src={ladyperson} alt="Default" />)}
                                        </div>
                                        <h6 className="dropdown-container-profile-name">{firstname} {lastname}</h6>
                                        <p className="dropdown-container-profile-role">{jobtitle}</p>
                                    </div>
                                    <div className="profile-person-flex">
                                        <NavLink to="/profileinfo" className="dropdown-item">My Profile</NavLink>
                                    </div>
                                    <div className="profile-person-flex">
                                        <NavLink to="/notification" className="dropdown-item">Notifications</NavLink>
                                    </div>
                                    <div className="profile-person-flex">
                                        <NavLink to="/queries" className="dropdown-item">Queries</NavLink>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                    <div className="profile-person-flex">
                                        <NavLink to="/settings" className="dropdown-item">Settings</NavLink>
                                    </div>
                                    <div className="profile-person-flex">
                                        <Link className="dropdown-item" href="#"
                                            onClick={() => {
                                                setToken(null);
                                                setUser(null);
                                                localStorage.clear();
                                                window.location.reload();
                                            }}
                                        >Logout</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export default MainNavbar;
