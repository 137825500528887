import React, { useEffect, useState } from "react";
import AllQueryTab from "./AllQueryTab";
import { useQuery } from "@tanstack/react-query";
import { getAllQueries } from "../api";
import MainNavbar from "../nav/MainNavbar";
import Bookmarked from "./Bookmarked";
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";
import { Helmet } from "react-helmet";
// import CheckCompleted from "../modal/CheckCompleted";
// import NoCloseModal from "../modal/NoCloseModal";

const MyQueries = () => {

    // const [isAddQueryModalOpen, setIsAddQueryModalOpen] = useState(false);

    const [isAddQueryModalOpen1, setIsAddQueryModalOpen1] = useState(false);

    let storedObject = localStorage.getItem('user');

    let firstname = '';
    let has_completed_profile = '';


    const { user } = useProtectedRoutesContext()

    const { data: queries } = useQuery({
        queryKey: ["getAllQueries"],
        queryFn: () => getAllQueries(),
    });


    useEffect(() => {
        if (has_completed_profile === "true") {
            setIsAddQueryModalOpen1(true);
            // console.log("Try", has_completed_profile);
        }
    }, []); // Empty dependency array to run only once when the component mounts

    return (
        <>
            <MainNavbar />
            <Helmet>
                <title>Connected Newsroom | Queries</title>
            </Helmet>
            <div className="container page-id-query">
                <div className="for-flex">
                    <h2 className="good-greeting">Hello, {user?.first_name}</h2>
                    {/* <button className="create-a-query" onClick={() => setIsAddQueryModalOpen(true)}>Create a Query</button> */}
                </div>

                <p className="my-queries-sub">My Queries</p>

                <ul className="nav nav-tabsub" id="myTab" role="tablist">
                    <li className="nav-iteming">
                        <a className="nav-linking active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">All Queries</a>
                    </li>
                    <li className="nav-iteming">
                        <a className="nav-linking" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Bookmarked Queries</a>
                    </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab"><AllQueryTab queries={queries} /></div>
                    <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab"><Bookmarked /></div>
                </div>

            </div>
            {/* <Modal isOpen={isAddQueryModalOpen} setIsOpen={setIsAddQueryModalOpen} title="Create a new Query" size="modal-normal">
                <AddQuery setIsOpen={setIsAddQueryModalOpen} queries={queries} />
            </Modal>
            <NoCloseModal isOpen={isAddQueryModalOpen1} setIsOpen={setIsAddQueryModalOpen1} title="Update Profile" size="xsm">
                <CheckCompleted setIsOpen1={setIsAddQueryModalOpen1} />
            </NoCloseModal> */}
        </>
    );
}

export default MyQueries;