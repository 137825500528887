import Footer from "../footer/Footer";
import Navbar from "../nav/NavBar";
import { Helmet } from "react-helmet";
import prejpg from "../assets/tick-circle1.png";
import coolbig from "../assets/coolbig.png";
import coolsecond from "../assets/our-process.png";
import fourth from "../assets/fourth-phase.png";

const Landing = () => {
    return (
        <div>
            <Helmet>
                <title>Connected Newsroom | We Connect journalists to Experts</title>
            </Helmet>
            <Navbar />
            <div className="main-background">
                <div className="container">
                    <div className="unique-container">
                        <h1 className="a-unique-source">A Unique source for Every Story</h1>
                        <h6 className="we-connect"> We connect journalists with sources for effective storytelling.</h6>
                    </div>
                    <div className="im-btn-div">
                        <button className="im-journalist-btn"><a href="https://journalist.yourconnectednewsroom.com/login">I'm a Journalist</a></button>
                        <button className="im-source-btn"><a href="https://yourconnectednewsroom.com/login">I'm an Expert</a></button>
                    </div>

                </div>
            </div>
            {/* <div className="container">
                <h4 className="our-process">Our process</h4>
                <div className="our-process-sub">
                    <h6>A Social Impact Platform that’s Good for All</h6>
                    <p> We connect journalists seeking expertise to include in their content with sources who have that expertise.</p>
                </div>
            </div> */}
            <div className="container mt-5">
                <h4 className="our-process">OUR FEATURES</h4>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="our-process-sub">
                            <h6>A social impact platform for journalists, newsroom sources, whistleblowers, subject matter experts, and public analysts.</h6>
                            {/* <p> We connect journalists seeking expertise to include in their content with sources who have that expertise.</p> */}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        {/* <h6>qwerty</h6> */}
                        <div className="cards-of-cards-div">
                            <img src={prejpg} alt="" className="school-impact-img" />
                            <h6 className="school-impact">Connect journalists and newsrooms to sources and experts.</h6>
                        </div>
                        <div className="cards-of-cards-div mt-3">
                            <img src={prejpg} alt="" className="school-impact-img" />
                            <h6 className="school-impact">Curate a credible database of commentators, policy analysts, experts and sources</h6>
                        </div>
                        <div className="cards-of-cards-div mt-3">
                            <img src={prejpg} alt="" className="school-impact-img" />
                            <h6 className="school-impact">Help journalists and newsrooms manage relationships with collaborators</h6>
                        </div>
                        <div className="cards-of-cards-div mt-3">
                            <img src={prejpg} alt="" className="school-impact-img" />
                            <h6 className="school-impact">Help journalists and newsrooms increase geographic reach and lower cost for investigations.</h6>
                        </div>
                    </div>
                </div>

            </div>

            <section>
                <div className="container margin-top-number">
                    <div className="d-flex-final">
                        <img src={coolbig} alt="" className="school-impact-big-img" />
                        <div className="col-new-part">
                            <h6 className="about-time">About Connected Newsroom</h6>
                            <p>Connected Newsroom is a civic tech platform designed to revolutionize the
                                way news is gathered, reported, and consumed by leveraging the collective efforts of news
                                sources across different parts of a country. This platform empowers individuals to contribute to
                                the news-making process, promoting citizen journalism and ensuring diverse and comprehensive coverage
                                of events and issues.</p>
                            <p>It is a news crowdsourcing platform that allows sources to report their locality and get national recognition.
                                It allows users to easily submit news content, including text, images, and videos thereby
                                facilitating real-time reporting of events as they unfold, allowing for timely and relevant news updates. </p>
                            <a href="https://yourconnectednewsroom.com/register"><button className="join-our-community">Join our Community</button></a>
                        </div>

                    </div>
                </div>
            </section>



            <section>
                <div className="container mt-5">
                    <div className="d-flex-final">
                        <div className="col-new-part">
                            <h6 className="about-time">Our Process</h6>
                            <p>Newsmakers from different parts of the country are allowed to sign up and create a profile, to
                                contribute to the news process.
                                They will create profiles as journalists, subject matter experts, news sources and community leaders. </p>
                            <p>All information is verified using geo tagging and community driven verification.
                                Users can corroborate or challenge reported news items or expert opinions. Users earn
                                profile badges and ratings as they provide credible information. </p>
                            <a href="https://yourconnectednewsroom.com/register"><button className="join-our-community">Create your Profile</button></a>
                        </div>
                        <img src={coolsecond} alt="" className="school-impact-big-img" />
                    </div>
                </div>
            </section>

            <section>
                <div className="container margin-top-number">
                    <h6 className="about-time">Our Coverage Area</h6>
                    <div className="coverage-img-div mt-3">
                        <img src={fourth} alt="" />
                    </div>
                </div>
            </section>
            <Footer />
        </div>

    )

}

export default Landing;