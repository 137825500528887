import React, { useState } from "react";
import { Helmet } from "react-helmet";
import MainNavbar from "../nav/MainNavbar";
import toggle from "../assets/ellipsis.png"
import { useProtectedRoutesContext } from "../context/ProtectedRoutes";
import Footer from "../footer/Footer";
import Modal from "../modal/Modal";
import AddLeak from "../modal/AddLeak";
import { DeleteLeak, getMyLeaks } from "../api";
import { useMutation, useQuery } from "@tanstack/react-query";
import jpeglevel from "../assets/greenjpeg.png"
import pdflevel from "../assets/Filetypes.png"
import bluepng from "../assets/bluepng.png"
import blackjpg from "../assets/blackjpg.png";
import { FaCaretSquareRight, FaFileArchive } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BiEditAlt } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import { renderErrorMessage, renderSuccessMessage } from "../assets/functions";
import ReactPaginate from "react-paginate";




const MyLeaks = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const [isAddQueryModalOpen, setIsAddQueryModalOpen] = useState(false);
    const { user } = useProtectedRoutesContext()
    const { data } = useQuery({
        queryKey: ["getMyLeaks", currentPage],
        queryFn: () => getMyLeaks(currentPage),
        suspense: true,
    });
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };
    const { mutate } = useMutation(DeleteLeak, {
        onSuccess: (data) => {
            renderSuccessMessage(data.message);

        },
        onError: (error) => renderErrorMessage(

        ),

    });

    // const resultsPerPage = 10;
    const [selectedleak, setselectedleak] = useState(null);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);


    return (
        <>
            <MainNavbar />
            <Helmet>
                <title>Connected Newsroom | My Leaks</title>
            </Helmet>
            <div className="container">
                <div className="for-flex">
                    <h2 className="good-greeting">Hello, {user?.first_name}</h2>
                </div>

                <div className="leaks-div mt-5">
                    <h3 className="my-leak-text">My Leaks</h3>
                    <button className="create-a-leak" onClick={() => setIsAddQueryModalOpen(true)}>Create new Leak</button>
                </div>
                <div>
                    {data?.data?.data && data.data.data.length > 0 ? (
                        <div>
                            {data?.data?.data?.map((leak, index) => (
                                <div className="the-leak-parent-div">
                                    <div className="toggle-left-container">
                                        {/* <img src={toggle} alt="" className="toggle-left" /> */}


                                        {/* Dropdown Menu */}
                                        <Link to={`/leaksdetails/${leak?.uuid}`}>
                                            <h1 className="the-leak-header-text">{leak?.title}</h1></Link>
                                        <div>

                                            <BiEditAlt className="leak-extra"
                                                onClick={() => {
                                                    setselectedleak(leak);
                                                    setIsEditModalOpen(true);
                                                }}
                                            />
                                            <MdDelete className="leak-extra"
                                                onClick={() => {
                                                    Swal.fire({
                                                        title: "Do you want to delete this Leak?",
                                                        showDenyButton: false,
                                                        showCancelButton: true,
                                                        confirmButtonText: "Delete Leak",
                                                        denyButtonText: `Cancel`
                                                    }).then((result) => {

                                                        if (result.isConfirmed) {
                                                            // Call mutate with leak uuid to delete the leak
                                                            mutate(leak?.uuid, {
                                                                onSuccess: () => {
                                                                    Swal.fire({
                                                                        title: 'Leak deleted successfully!',
                                                                        icon: 'success',
                                                                    }).then(() => {
                                                                        // Reload the page or re-fetch data after successful deletion
                                                                        window.location.reload(); // This will reload the page
                                                                        // Or, you can trigger a re-fetch of data instead if you don't want a full page reload
                                                                        // refetch(); // Assuming you have a useQuery with refetch
                                                                    });
                                                                },
                                                                onError: (error) => {
                                                                    Swal.fire({
                                                                        title: 'Error deleting leak!',
                                                                        text: error.message,
                                                                        icon: 'error',
                                                                    });
                                                                },
                                                            });
                                                        }
                                                    });
                                                }}
                                            />
                                        </div>

                                    </div>


                                    <h6 className="query-sub-description">{leak?.description}</h6>
                                    {Array.isArray(leak?.tags) && leak.tags.length > 0 ? (
                                        <div>
                                            <h6 className="categories-text">Categories</h6>
                                            <div className="category-display-div">
                                                {leak.tags.map((leaktag, index) => (
                                                    <div key={index} className="cat-new-options-div">
                                                        <h6 className="cat-new-options">{leaktag}</h6>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : null}

                                    {Array.isArray(leak?.attachments) && leak.attachments.length > 0 ? (
                                        <div>
                                            <h6 className="categories-text">Attachments</h6>

                                            <div key={index} className="file-format">
                                                {leak?.attachments.map((attachment, idx) => {
                                                    const extension = attachment.split('.').pop().toLowerCase();

                                                    return (
                                                        <div key={idx} >
                                                            {extension === "pdf" && (
                                                                <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                                    <img className="tailwind" src={pdflevel} alt="PDF" />
                                                                </a>
                                                            )}
                                                            {extension === "jpeg" && (
                                                                <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                                    <img className="tailwind" src={jpeglevel} alt="JPEG" />
                                                                </a>
                                                            )}
                                                            {extension === "jpg" && (
                                                                <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                                    <img className="tailwind" src={blackjpg} alt="JPG" />
                                                                </a>
                                                            )}
                                                            {extension === "png" && (
                                                                <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                                    <img className="tailwind" src={bluepng} alt="PNG" />
                                                                </a>
                                                            )}
                                                            {extension === "mp4" && (
                                                                <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                                    <div className="video-play-icon">
                                                                        <img className="tailwind" src={<FaCaretSquareRight className="tailwind" />} alt="Video" />

                                                                    </div>
                                                                </a>
                                                            )}
                                                            {extension === "tmp" && (
                                                                <a href={attachment} target="_blank" rel="noopener noreferrer" download>
                                                                    <div className="video-play-icon1">
                                                                        <FaFileArchive className="tailwind" /> {/* Icon for ZIP files */}
                                                                    </div>
                                                                </a>
                                                            )}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ) : null}

                                    <Modal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} title="Edit Leak Details" size="large">
                                        <AddLeak myownleak={selectedleak} setIsOpen={setIsEditModalOpen} />
                                    </Modal>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="nothing-leaks">
                            <h6> You dont have any leaks </h6>
                        </div>

                    )}
                </div>

                <ReactPaginate
                    previousLabel={'< Prev'}
                    previousClassName="previous-class"
                    nextLabel={'Next >'}
                    nextClassName="next-class"
                    pageCount={data?.data?.total / 10}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'active-paginate'}
                    renderOnZeroPageCount={null}
                    breakLabel="..."
                />
            </div>
            <Modal isOpen={isAddQueryModalOpen} setIsOpen={setIsAddQueryModalOpen} title="Create a Leak" size="modal-normal">
                <AddLeak setIsOpen={setIsAddQueryModalOpen} />
            </Modal >
            <Footer />
        </>
    )
}
export default MyLeaks;